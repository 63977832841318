div.series-detail-box {
  width: 100%;
  overflow: hidden;
  background-color: #FFF;
  padding: 10px 10px 20px 10px;
  border-bottom: 1px solid #e2e2e2;
}
div.series-detail-box div.list-rank-div {
  margin-bottom: 3px;
}
div.series-detail-box div.list-rank-div span.rank-span {
  background-color: #268bc7;
  color: #FFF;
  padding: 6px 3px 3px 3px;
}
div.series-detail-box div.list-rank-div span.rank-span span.rank-no-span {
  font-size: 20px;
}
div.series-detail-box div.series-img-div {
  float: left;
  overflow: hideen;
  width: 30%;
  padding-right: 10px;
}
div.series-detail-box div.series-img-div img {
  width: 100%;
}
div.series-detail-box div.series-info-div {
  float: left;
  overflow: hideen;
  width: 70%;
}
div.series-detail-box div.series-info-div a {
  text-decoration: none;
}
div.series-detail-box div.series-info-div a div.series-title-jp {
  display: inline-block;
  margin-top: 5px;
  font-weight: bold;
}
div.series-detail-box div.series-info-div a div.series-title-en {
  display: inline-block;
  margin-left: 5px;
  font-size: 80%;
}
div.series-detail-box div.series-info-div div.rating-star-div img {
  width: 100%;
  max-width: 100px;
}
div.series-detail-box div.series-introduction-div {
  float: left;
  clear: both;
  width: 100%;
  font-size: 80%;
}
div.series-detail-box div.series-tag-div {
  float: left;
  clear: both;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 80%;
  border: 1px solid #0e0e0e;
}
@media all and (min-width: 481px) {
  div.series-detail-box div.series-img-div {
    float: left;
    width: 20%;
  }
  div.series-detail-box div.series-info-div {
    float: right;
    width: 80%;
  }
  div.series-detail-box div.series-introduction-div,
  div.series-detail-box div.series-tag-div {
    clear: none;
    float: right;
    width: 80%;
  }
}
