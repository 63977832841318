div#contents div.two-column-7525 div.main-column div.content-title {
  width: 100%;
  background: #FFF;
  text-align: center;
  padding: 10px 10px 0 10px;
}
div#contents div.two-column-7525 div.main-column div.content-title h1.genre-name {
  font-size: 120%;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
div#contents div.two-column-7525 div.main-column div.load-control {
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.popular-tags-div {
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.popular-tags-div div.popular-tags-header {
  text-align: center;
  font-weight: bold;
}
