div.sub-menu {
  width: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
div.sub-menu a div.sub-menu-item {
  display: inline-block;
  width: 50%;
  padding: 5px;
  text-align: center;
  background-color: #0e3576;
  opacity: 0.8;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #FFF;
}
div.sub-menu a div.sub-menu-item.selected,
div.sub-menu a div.sub-menu-item:hover {
  cursor: pointer;
  opacity: 0.6;
}
@media all and (min-width: 481px) {
  div.sub-menu a div.sub-menu-item {
    width: 25%;
  }
}
