#footer div.page-top-button {
  display: block;
  float: right;
  margin-right: 20%;
  position: relative;
  top: -30px;
  height: 30px;
  margin-bottom: -30px;
  padding: 5px 10px;
  background-color: #268bc7;
  color: #FFF;
  font-size: 80%;
  text-align: center;
}
#footer div.page-top-button:hover {
  cursor: pointer;
}
#footer #info-bar-wrap {
  width: 100%;
  background-color: #d7e2ef;
}
#footer #info-bar-wrap #info-bar {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}
#footer #info-bar-wrap #info-bar div.author-msg {
  width: 100%;
  min-height: 200px;
  padding: 20px;
  border-bottom: 1px solid #0e3576;
}
#footer #info-bar-wrap #info-bar div.author-msg div.author-msg-title {
  text-align: center;
  font-weight: bold;
}
#footer #info-bar-wrap #info-bar div.author-msg div.author-msg-text {
  padding-top: 10px;
  font-size: 80%;
}
#footer #info-bar-wrap #info-bar div.author-msg div.social-button-div {
  padding-top: 10px;
  text-align: center;
}
#footer #info-bar-wrap #info-bar div.send-feedback {
  width: 100%;
  min-height: 200px;
  padding: 20px;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-title {
  text-align: center;
  font-weight: bold;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-text {
  padding-top: 10px;
  font-size: 80%;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-form {
  padding: 10px;
  text-align: center;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-form textarea.feedback-textarea {
  width: 100%;
  height: 50px;
  text-align: left;
  resize: vertical;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-form div.feedback-button {
  width: 80px;
  font-size: 80%;
  text-align: center;
  margin: 0 auto;
  padding: 5px 10px;
  background-color: #268bc7;
  color: #FFF;
  border-bottom: 3px solid #0e3576;
}
#footer #info-bar-wrap #info-bar div.send-feedback div.send-feedback-form div.feedback-button:hover {
  cursor: pointer;
}
#footer #rights-bar-wrap {
  width: 100%;
  background-color: #000;
  color: #FFF;
}
#footer #rights-bar-wrap #rights-bar {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}
#footer #rights-bar-wrap div.rights-text {
  width: 100%;
  text-align: center;
}
@media all and (min-width: 481px) {
  #footer #info-bar-wrap #info-bar {
    display: table;
    border-left: 1px solid #0e3576;
    border-right: 1px solid #0e3576;
  }
  #footer #info-bar-wrap #info-bar div.author-msg {
    display: table-cell;
    width: 50%;
    border-right: 1px solid #0e3576;
    border-bottom: none;
  }
  #footer #info-bar-wrap #info-bar div.send-feedback {
    display: table-cell;
    width: 50%;
  }
}
