div.two-column-7525 div.main-column div.load-control {
  background-color: #FFF;
  padding: 10px;
}
div.two-column-7525 div.main-column div.search-tag {
  width: 100%;
  background: #FFF;
  text-align: center;
  padding: 10px;
}
div.two-column-7525 div.main-column div.search-tag span.search-tag-name {
  font-size: 120%;
  font-weight: bold;
}
div.two-column-7525 div.main-column div.all-tags-div {
  width: 100%;
  background-color: #FFF;
  padding: 10px;
}
div.two-column-7525 div.main-column div.all-tags-div div.all-tags-msg {
  text-align: center;
  font-size: 120%;
  font-weight: bold;
  margin-bottom: 20px;
}
div.two-column-7525 div.side-column div.tag-search-div {
  text-align: center;
  background-color: #FFF;
  margin-bottom: 10px;
  padding: 10px;
}
div.two-column-7525 div.side-column div.tag-search-div div.tag-search-caption {
  font-weight: bold;
}
div.two-column-7525 div.side-column div.popular-tags-div {
  background-color: #FFF;
  padding: 10px;
}
div.two-column-7525 div.side-column div.popular-tags-div div.popular-tags-header {
  text-align: center;
  font-weight: bold;
}
div.coming-soon-box {
  display: none;
}
@media all and (min-width: 481px) {
  div.all-tags-div {
    padding: 30px;
  }
  div.all-tags-div div.all-tags-msg {
    margin-bottom: 10px;
  }
}
