div.series-box {
  width: 100%;
  overflow: hidden;
  background-color: #FFF;
}
div.series-box a {
  text-decoration: none;
  width: 100%;
  height: auto;
  box-shadow: rgba(37, 61, 0, 0.2) 0px 0px 1px 1px;
  -webkit-box-shadow: rgba(37, 61, 0, 0.2) 0px 0px 1px 1px;
  -moz-box-shadow: rgba(37, 61, 0, 0.2) 0px 0px 1px 1px;
}
div.series-box a:hover {
  box-shadow: rgba(37, 61, 0, 0.3) 0px 0px 3px 1px;
  -webkit-box-shadow: rgba(37, 61, 0, 0.3) 0px 0px 3px 1px;
  -moz-box-shadow: rgba(37, 61, 0, 0.3) 0px 0px 3px 1px;
}
div.series-box a div.series-img-div {
  float: none;
  width: 100%;
  text-decoration: none;
}
div.series-box a div.series-img-div img.series-img {
  width: 100%;
  display: block;
  margin: 0;
}
div.series-box a div.series-img-div img.series-img:hover {
  cursor: pointer;
}
div.series-box a div.series-info-div {
  float: none;
  width: 100%;
  border: 1px solid #e2e2e2;
  padding-top: 2px;
}
div.series-box a div.series-info-div div.series-title-jp {
  width: 100%;
  padding: 2px;
  text-align: center;
  line-height: 110%;
  color: #343434;
  font-size: 80%;
  font-weight: bold;
}
div.series-box a div.series-info-div div.series-title-jp:hover {
  cursor: pointer;
  color: #0080ff;
}
div.series-box a div.series-info-div div.series-title-en {
  width: 100%;
  padding: 2px;
  text-align: center;
  line-height: 110%;
  color: #343434;
  font-size: 70%;
}
div.series-box a div.series-info-div div.rating-star-div {
  padding: 2px auto;
  text-align: center;
}
div.series-box a div.series-info-div div.rating-star-div img {
  width: 70%;
  max-width: 80px;
  height: auto;
}
