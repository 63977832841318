div.series-box-ranking {
  width: 100%;
  background-color: #FFF;
  border: 3px solid #268bc7;
  padding: 10px;
}
div.series-box-ranking div.ranking-title-div {
  padding-left: 10px;
}
div.series-box-ranking div.ranking-title-div span.ranking-title {
  color: #0e3576;
  font-weight: bold;
  padding: 3px 5px;
  font-size: 120%;
}
div.series-box-ranking div.series-box-div {
  display: none;
  vertical-align: top;
  width: 33%;
  padding: 10px;
}
div.series-box-ranking div.series-box-div.sp {
  display: inline-block;
}
div.series-box-ranking div.series-box-div div.ranking-img-div {
  text-align: center;
}
div.series-box-ranking div.series-box-div div.ranking-img-div img.ranking-img {
  width: auto;
  height: 35px;
  display: block;
  margin: auto;
}
div.series-box-ranking div.series-box-div div.ranking-img-div div.ranking-no {
  height: 35px;
}
@media all and (min-width: 481px) {
  div.series-box-ranking div.series-box-div {
    display: none;
    width: 20%;
  }
  div.series-box-ranking div.series-box-div.tb {
    display: inline-block;
  }
}
@media all and (min-width: 769px) {
  div.series-box-ranking div.series-box-div {
    display: none;
    width: 14%;
  }
  div.series-box-ranking div.series-box-div.pc {
    display: inline-block;
  }
}
