div.eng-review-header {
  margin-bottom: 20px;
}
div.eng-review-header div.eng-info {
  margin-bottom: 20px;
}
div.eng-review-header div.eng-info div.eng-info-label,
div.eng-review-header div.eng-info div.eng-level,
div.eng-review-header div.eng-info div.eng-accent-type {
  display: inline-block;
  border: 1px solid #000;
  border-right: none;
  background-color: #FFF;
  padding: 2px 5px;
  font-size: 90%;
  font-weight: bold;
}
div.eng-review-header div.eng-info div.eng-info-label {
  border: none;
  font-weight: bold;
}
div.eng-review-header div.eng-info div.eng-accent-type {
  border-right: 1px solid #000;
}
div.eng-review-header div.eng-info div.eng-level.level1 {
  background-color: #e1ffe0;
}
div.eng-review-header div.eng-info div.eng-level.level2 {
  background-color: #e6f5ff;
}
div.eng-review-header div.eng-info div.eng-level.level3 {
  background-color: #e6f5ff;
}
div.eng-review-header div.eng-info div.eng-level.level4 {
  background-color: #e6f5ff;
}
div.eng-review-header div.eng-info div.eng-level.level5 {
  background-color: #fcffe9;
}
div.eng-review-header div.eng-info div.eng-level.level6 {
  background-color: #fcffe9;
}
div.eng-review-header div.eng-info div.eng-level.level7 {
  background-color: #fcffe9;
}
div.eng-review-header div.eng-info div.eng-level.level8 {
  background-color: #ffedd0;
}
div.eng-review-header div.eng-info div.eng-level.level9 {
  background-color: #ffcbc8;
}
div.eng-review-header div.eng-level-help {
  border: 1px solid #b2b2b2;
}
div.eng-review-header div.eng-level-help div.level-icon {
  display: inline-block;
  margin-left: 20px;
}
div.eng-review-header div.eng-level-help div.level-icon:first-child {
  margin-left: 10px;
}
div.eng-review-header div.eng-level-help div.level-icon img.level-icon {
  width: 12px;
  height: 12px;
}
div.eng-review-header div.eng-level-help div.level-label {
  display: inline-block;
  margin-left: 5px;
  font-size: 80%;
}
div.eng-review-box div.header {
  height: 25px;
}
div.eng-review-box div.header div.eng-level-title.level1 {
  background-color: #e1ffe0;
}
div.eng-review-box div.header div.eng-level-title.level2 {
  background-color: #e6f5ff;
}
div.eng-review-box div.header div.eng-level-title.level3 {
  background-color: #e6f5ff;
}
div.eng-review-box div.header div.eng-level-title.level4 {
  background-color: #e6f5ff;
}
div.eng-review-box div.header div.eng-level-title.level5 {
  background-color: #fcffe9;
}
div.eng-review-box div.header div.eng-level-title.level6 {
  background-color: #fcffe9;
}
div.eng-review-box div.header div.eng-level-title.level7 {
  background-color: #fcffe9;
}
div.eng-review-box div.header div.eng-level-title.level8 {
  background-color: #ffedd0;
}
div.eng-review-box div.header div.eng-level-title.level9 {
  background-color: #ffcbc8;
}
div.eng-review-box div.header div.eng-level-title,
div.eng-review-box div.header div.subtitle-type,
div.eng-review-box div.header div.eng-level-total {
  float: left;
  border: 1px solid #000;
  font-size: 80%;
  height: 25px;
  background-color: #FFF;
  padding: 2px 5px;
}
div.eng-review-box div.header div.eng-level-total img.level-icon {
  margin: 3px 0 3px 2px;
  width: 14px;
  height: 14px;
}
div.eng-review-box div.header div.eng-level-title,
div.eng-review-box div.header div.subtitle-type {
  border-right: none;
}
div.eng-review-box div.header div.nickname {
  float: left;
  margin-left: 10px;
  font-weight: bold;
}
div.eng-review-box hr.divider {
  clear: both;
  background-color: #FFF;
  border: none;
  padding-bottom: 1px;
}
div.eng-review-box div.eng-review-text {
  clear: both;
  witdh: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #000;
  line-height: 180%;
  font-size: 90%;
}
div.eng-review-box div.eng-review-text {
  color: #19283C;
  position: relative;
  line-height: 32px;
  background: #F9F9F9;
  border: 1px solid #19283C;
  z-index: 0;
}
div.eng-review-box div.eng-review-text:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  margin-left: -9px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #F6F6F6 transparent;
  z-index: 0;
}
div.eng-review-box div.eng-review-text:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 20px;
  margin-left: -10px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #19283C transparent;
  z-index: -1;
}
@media all and (min-width: 481px) {
  div.eng-review-box div.header {
    margin-left: 10px;
  }
  div.eng-review-box div.eng-review-text {
    margin: 10px;
    padding: 15px;
  }
}
@media all and (min-width: 769px) {
  div.eng-review-box div.eng-review-text {
    margin: 10px;
    padding: 20px;
  }
}
