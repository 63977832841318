div#contents div.two-column-7525 div.main-column div.content-title {
  width: 100%;
  background: #FFF;
  padding: 10px 10px 0 10px;
  text-align: center;
}
div#contents div.two-column-7525 div.main-column div.content-title h1.ranking-title {
  font-size: 120%;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
div#contents div.two-column-7525 div.main-column div.load-control {
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.other-ranking {
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.other-ranking div.ranking-header {
  margin-top: 10px;
}
