div.tag-list {
  width: 100%;
  overflow: hidden;
}
div.tag-list.large {
  font-size: 16px;
}
div.tag-list.medium {
  font-size: 12px;
}
div.tag-list.small {
  font-size: 10px;
}
div.tag-list div.tag-div {
  display: inline-block;
  margin: 3px 7px;
}
div.tag-list div.tag-div:hover {
  cursor: pointer;
}
div.tag-list div.tag-div.large {
  font-size: 130%;
}
div.tag-list div.tag-div.medium {
  font-size: 100%;
}
div.tag-list div.tag-div.small {
  font-size: 80%;
}
