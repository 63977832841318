div.load-control div.load-content-div {
  width: 100%;
}
div.load-control div.load-button-div {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}
div.load-control div.load-button-div div.load-button {
  width: 80%;
  margin: auto;
  max-width: 300px;
  padding: 5px 10px;
  font-size: 90%;
  border: 1px solid #000;
  background-color: #FFF;
}
div.load-control div.load-button-div div.load-button:hover {
  cursor: pointer;
  background-color: #F9F9F9;
}
