div#contents div.level-list {
  width: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
div#contents div.level-list a div.level-item {
  display: inline-block;
  width: 50%;
  padding: 5px;
  text-align: center;
  background-color: #0e3576;
  opacity: 0.8;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #FFF;
}
div#contents div.level-list a div.level-item.selected,
div#contents div.level-list a div.level-item:hover {
  cursor: pointer;
  opacity: 0.6;
}
div#contents div.two-column-7525 div.main-column div.content-title {
  width: 100%;
  background: #FFF;
  text-align: center;
  padding: 10px 10px 0 10px;
}
div#contents div.two-column-7525 div.main-column div.content-title h1.level-name {
  font-size: 120%;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
div#contents div.two-column-7525 div.main-column div.load-control {
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.level-list {
  width: 100%;
  background-color: #FFF;
  padding: 10px;
}
div#contents div.two-column-7525 div.side-column div.level-list div.level-list-header {
  font-weight: bold;
}
div#contents div.two-column-7525 div.side-column div.level-list div.level-item {
  margin-top: 5px;
}
