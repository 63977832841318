div.series-title-div {
  width: 100%;
  padding: 5px 10px;
  background-color: #FFF;
}
div.series-title-div div.series-title {
  width: 100%;
  padding-left: 20px;
}
div.series-title-div div.series-title h1.series-title-jp {
  display: inline-block;
  font-size: 150%;
  font-weight: bold;
  color: #363636;
  margin: 0;
  padding: 0;
}
div.series-title-div div.series-title div.series-title-en {
  display: inline-block;
  padding-left: 10px;
}
div.series-title-div div.series-title div.series-title-en strong {
  font-weight: normal;
}
div.two-column-28 div.side-column div.series-box {
  width: 100%;
  background-color: #FFF;
  text-align: center;
}
div.two-column-28 div.side-column div.series-box img.series-img {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: auto;
}
div.two-column-28 div.side-column div.series-box div.rating-star-div {
  padding: 5px 10px;
}
div.two-column-28 div.side-column div.series-box div.my-list-button {
  width: 80%;
  margin: 0 auto;
  background-color: #0a60ef;
  color: #FFF;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}
div.two-column-28 div.side-column div.series-box div.my-list-button:hover {
  cursor: pointer;
}
div.two-column-28 div.side-column div.vertical-banner {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #FFF;
  text-align: center;
}
div.two-column-28 div.main-column div.social-button-div {
  text-align: right;
}
div.two-column-28 div.main-column div.field-list-div div.field-div {
  clear: both;
  width: 100%;
  overflow: hidden;
}
div.two-column-28 div.main-column div.field-list-div div.field-div div.field-name {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #dedede;
}
div.two-column-28 div.main-column div.field-list-div div.field-div div.field-content {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #dedede;
}
div.two-column-28 div.main-column div.field-list-div div.field-div div.field-content div.field-rating-star img,
div.two-column-28 div.main-column div.field-list-div div.field-div div.field-content div.field-level-meter img {
  width: 100%;
  max-width: 120px;
  height: auto;
}
div.two-column-28 div.main-column div.field-list-div div.field-div.connected div.field-name {
  display: none;
}
div.two-column-28 div.main-column div.field-list-div div.field-div.connected div.field-content {
  border-top: none;
}
div.two-column-28 div.main-column div.basic-data {
  background-color: #FFF;
  margin: 10px;
  padding: 20px;
}
div.two-column-28 div.main-column div.basic-data div.field-list-div div.field-div div.field-content div.genre-list-div a.genre-link {
  margin-left: 10px;
}
div.two-column-28 div.main-column div.basic-data div.field-list-div div.field-div div.field-content div.genre-list-div a.genre-link:first-child {
  margin-left: 0;
}
div.two-column-28 div.main-column div.series-introduction {
  line-height: 180%;
}
div.two-column-28 div.main-column div.series-trailer div.youtube-video-wrap {
  width: 90%;
  max-width: 500px;
  margin: 10px auto;
}
div.two-column-28 div.main-column div.series-trailer div.youtube-video {
  text-align: center;
  position: relative;
  width: 100%;
  padding: 75% 0 0 0;
}
div.two-column-28 div.main-column div.series-trailer div.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div.two-column-28 div.main-column div.series-param div.series-param-header {
  width: 100%;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 5px;
}
div.two-column-28 div.main-column div.series-tag div.series-tag-footer {
  width: 100%;
  text-align: right;
  padding-right: 10px;
  padding-top: 3px;
  font-size: 80%;
}
div.two-column-28 div.main-column div.english-data div.eng-review-link {
  text-align: right;
}
div.two-column-28 div.main-column div.english-data div.eng-review-box-div {
  margin-bottom: 10px;
}
div.two-column-28 div.main-column div.user-review div.user-review-header {
  width: 100%;
  text-align: right;
  padding-right: 10px;
}
div.two-column-28 div.main-column div.user-review div.review-box-div {
  margin-bottom: 10px;
}
div.two-column-28 div.main-column div.availability div.service-list a.service-link img.service-logo {
  height: 30px;
  width: auto;
  margin-right: 10px;
}
div.two-column-28 div.main-column div.availability div.service-list a.service-link-disabled:hover {
  cursor: default;
}
div.two-column-28 div.main-column div.availability div.service-list a.service-link-disabled img.service-logo {
  height: 30px;
  width: auto;
  margin-right: 10px;
  opacity: 0.2;
  -webkit-filter: grayscale(1);
  filter: gray;
  filter: grayscale(1);
}
div.two-column-28 div.main-column div.availability div.service-ranking a.service-ranking-link {
  font-size: 80%;
  margin-right: 10px;
}
div.two-column-28 div.main-column div.availability div.service-hint {
  margin-top: 15px;
}
div.two-column-28 div.main-column div.availability div.service-hint div {
  margin-top: 5px;
  font-size: 80%;
}
div.param-slider-div {
  width: 100%;
}
@media all and (min-width: 481px) {
  div.two-column-28 div.side-column div.series-box {
    margin-top: 10px;
  }
  div.two-column-28 div.side-column div.series-box img.series-img {
    max-width: 200px;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div {
    display: table;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div div.field-name {
    display: table-cell;
    vertical-align: middle;
    width: 20%;
    padding: 10px;
    border-top: 1px solid #FFF;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div div.field-content {
    display: table-cell;
    width: 80%;
    padding: 10px;
    border-bottom: none;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div:last-child div.field-content {
    border-bottom: 1px solid #dedede;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div.connected div.field-name {
    display: table-cell;
  }
  div.two-column-28 div.main-column div.field-list-div div.field-div.connected div.field-name,
  div.two-column-28 div.main-column div.field-list-div div.field-div.connected div.field-content {
    border-top: none;
  }
  div.two-column-28 div.main-column div.series-introduction {
    padding: 10px;
  }
  div.two-column-28 div.main-column div.availability div.service-list a.service-link img.service-logo {
    height: 45px;
  }
  div.two-column-28 div.main-column div.availability div.service-list a.service-link-disabled img.service-logo {
    height: 45px;
  }
}
