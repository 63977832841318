div.review-box {
  width: 100%;
}
div.review-box div.review-user div.review-sex-age {
  display: inline-block;
  padding: 3px 5px;
  font-size: 70%;
  border: 1px solid #000;
}
div.review-box div.review-user div.review-sex-age.male {
  background-color: #cef0fb;
}
div.review-box div.review-user div.review-sex-age.female {
  background-color: #fbeaf0;
}
div.review-box div.review-user div.review-sex-age.unknown {
  background-color: #e6e6e6;
}
div.review-box div.review-user div.review-sex-age span.review-age {
  font-size: 120%;
  padding-right: 1px;
}
div.review-box div.review-user div.user-nickname {
  display: inline-block;
  margin-left: 10px;
}
div.review-box div.review-text-box {
  witdh: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #000;
  line-height: 180%;
}
div.review-box div.review-text-box div.rating-star-div {
  display: inline-block;
}
div.review-box div.review-text-box div.rating-star-div img {
  height: 16px;
  width: auto;
}
div.review-box div.review-text-box div.review-title {
  font-weight: bold;
}
div.review-box div.review-text-box div.review-text {
  font-size: 90%;
  margin-top: 10px;
}
div.review-box div.review-text-box div.review-text p {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.review-box div.review-text-box div.spoiler-link {
  color: #1111CC;
}
div.review-box div.review-text-box div.spoiler-link:hover {
  cursor: pointer;
}
div.review-box div.review-text-box {
  color: #19283C;
  position: relative;
  display: inline-block;
  line-height: 32px;
  background: #F9F9F9;
  border: 1px solid #19283C;
  z-index: 0;
}
div.review-box div.review-text-box:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  margin-left: -9px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #F6F6F6 transparent;
  z-index: 0;
}
div.review-box div.review-text-box:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 20px;
  margin-left: -10px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #19283C transparent;
  z-index: -1;
}
@media all and (min-width: 481px) {
  div.review-box div.review-user div.review-sex-age {
    margin-left: 10px;
  }
  div.review-box div.review-text-box {
    margin: 10px;
    padding: 15px;
  }
  div.review-box div.review-text-box div.review-title {
    display: inline-block;
    margin-left: 10px;
  }
}
@media all and (min-width: 769px) {
  div.review-box div.review-text-box {
    margin: 10px;
    padding: 20px;
  }
}
