div#header div#adblock-alert {
  width: 100%;
  background-color: #FFEFEF;
  color: #A2462F;
  text-align: center;
}
div#header div#user-bar-wrap {
  width: 100%;
  background-color: #000;
}
div#header div#user-bar-wrap div#user-bar {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  color: #FFF;
}
div#header div#user-bar-wrap div#user-bar div.site-description {
  display: none;
  font-size: 80%;
  padding: 3px;
}
div#header div#user-bar-wrap div#user-bar div.nickname {
  float: right;
  margin-right: 20px;
}
div#header div#user-bar-wrap div#user-bar div.mypage-link,
div#header div#user-bar-wrap div#user-bar div.logout-link,
div#header div#user-bar-wrap div#user-bar div.login-link,
div#header div#user-bar-wrap div#user-bar div.signup-link {
  float: right;
  margin-right: 20px;
}
div#header div#user-bar-wrap div#user-bar div.mypage-link a,
div#header div#user-bar-wrap div#user-bar div.logout-link a,
div#header div#user-bar-wrap div#user-bar div.login-link a,
div#header div#user-bar-wrap div#user-bar div.signup-link a {
  color: #FFF;
}
div#header div#logo-bar {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}
div#header div#logo-bar div.logo-div {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
div#header div#logo-bar div.logo-div a img.logo-img {
  width: 100%;
  height: auto;
}
div#header div#logo-bar div.tagline-div {
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
}
div#header div#logo-bar div.tagline-div a {
  text-decoration: none;
  color: #000;
}
div#header div#logo-bar div.tagline-div a strong.tagline-highlight {
  color: #1457D0;
}
div#header div#logo-bar div.series-search-div {
  width: 100%;
  padding: 10px;
  background-color: #E2E2E2;
}
div#header div#logo-bar div.series-search-div div.series-search-caption {
  width: 100%;
}
div#header div#logo-bar div.series-search-div div.series-search-main {
  width: 100%;
}
div#header div#menu-bar div.menu-item-div {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
div#header div#menu-bar div.menu-item-div div.menu-item {
  display: inline-block;
  width: 50%;
  height: 35px;
  overflow: hidden;
  background-color: #268bc7;
  vertical-align: middle;
  text-align: center;
  padding: 5px 0;
}
div#header div#menu-bar div.menu-item-div div.menu-item:hover,
div#header div#menu-bar div.menu-item-div div.menu-item.selected {
  cursor: pointer;
  opacity: 0.8;
  background-color: #0e3576;
}
div#header div#menu-bar div.menu-item-div div.menu-item a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #FFF;
}
@media all and (min-width: 481px) {
  div#header div#user-bar-wrap div#user-bar div.site-description {
    display: block;
    float: left;
    margin-left: 10px;
  }
  div#header div#logo-bar div.logo-div {
    float: left;
    width: 30%;
    max-width: 150px;
    margin: 0;
    padding: 5px 5px 5px 10px;
  }
  div#header div#logo-bar div.tagline-div {
    float: left;
    width: 70%;
    text-align: left;
    padding-top: 15px;
    padding-left: 10px;
    font-size: 110%;
  }
  div#header div#logo-bar div.series-search-div {
    clear: both;
    text-align: right;
  }
  div#header div#logo-bar div.series-search-div div.series-search-caption {
    display: inline-block;
    width: 100px;
    font-weight: bold;
  }
  div#header div#logo-bar div.series-search-div div.series-search-main {
    display: inline-block;
    width: 320px;
  }
  div#header div#menu-bar div.menu-item-div {
    display: table;
  }
  div#header div#menu-bar div.menu-item-div div.menu-item {
    display: table-cell;
    float: none;
    width: 25%;
  }
}
@media all and (min-width: 769px) {
  div#header div#logo-bar {
    text-align: right;
  }
  div#header div#logo-bar div.logo-div {
    float: left;
    width: 20%;
    max-width: 150px;
    margin: 0;
    padding: 5px 5px 5px 10px;
  }
  div#header div#logo-bar div.tagline-div {
    float: left;
    width: 40%;
    max-width: 350px;
    text-align: left;
    padding-top: 15px;
    padding-left: 10px;
    font-size: 110%;
  }
  div#header div#logo-bar div.series-search-div {
    clear: none;
    float: right;
    width: 40%;
    max-width: 450px;
    text-align: left;
    background-color: #FFF;
  }
  div#header div#logo-bar div.series-search-div div.series-search-caption {
    display: inline-block;
    width: 100px;
    font-weight: bold;
  }
  div#header div#logo-bar div.series-search-div div.series-search-main {
    display: inline-block;
    width: 320px;
  }
}
