div.simple-series-list {
  border: 1px solid #E2E2E2;
  overflow: hidden;
}
div.simple-series-list div.simple-series-item {
  clear: both;
  border-bottom: 1px solid #E2E2E2;
  overflow: hidden;
}
div.simple-series-list div.simple-series-item:last-child {
  border-bottom: none;
}
div.simple-series-list div.simple-series-item a.series-link div.series-img-div {
  float: left;
  width: 15%;
}
div.simple-series-list div.simple-series-item a.series-link div.series-img-div img {
  width: 100%;
  height: auto;
}
div.simple-series-list div.simple-series-item a.series-link div.series-content-div {
  float: left;
  width: 85%;
  padding-left: 10px;
}
div.simple-series-list div.simple-series-item a.series-link div.series-content-div div.series-title-jp {
  float: left;
}
div.simple-series-list div.simple-series-item a.series-link div.series-content-div div.series-title-en {
  float: left;
  clear: both;
  font-size: 80%;
}
div.simple-series-list div.simple-series-item a.series-link div.series-content-div div.series-rating-div {
  float: left;
  clear: both;
  height: 20px;
}
div.simple-series-list div.simple-series-item a.series-link div.series-content-div div.series-rating-div img {
  height: 20px;
  width: auto;
}
@media all and (min-width: 481px) {
  div.simple-series-list div.simple-series-item a.series-link div.series-img-div {
    width: 5%;
  }
  div.simple-series-list div.simple-series-item a.series-link div.series-content-div {
    width: 95%;
    padding-left: 20px;
  }
}
