div.tab-switch {
  width: 100%;
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
}
div.tab-switch div.tab-name-div {
  display: table;
}
div.tab-switch div.tab-name-div h2.tab-name {
  display: table-cell;
  vertical-align: middle;
  font-size: 90%;
  padding: 5px 10px;
  background-color: #FFF;
  border-right: 1px solid #f1f1f1;
}
div.tab-switch div.tab-name-div h2.tab-name:last-child {
  border-right: none;
}
div.tab-switch div.tab-name-div h2.tab-name:hover {
  cursor: pointer;
}
div.tab-switch div.tab-name-div h2.tab-name.selected {
  border-bottom: 3px solid #008000;
}
div.tab-switch div.tab-element-div {
  border-top: 2px solid #F0F0F0;
}
div.tab-switch div.tab-element-div div.tab-element {
  width: 100%;
  padding: 20px;
  background-color: #FFF;
}
div.tab-switch div.tab-element-div div.tab-element.hide {
  display: none;
}
