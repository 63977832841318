* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
body {
  margin: 0;
  font-size: 16px;
}
div#header-wrap {
  width: 100%;
}
div#header-wrap div#header {
  width: 100%;
}
div#contents-wrap {
  width: 100%;
  height: auto;
}
div#contents-wrap div#contents {
  width: 100%;
  height: auto;
  max-width: 1100px;
  background-color: #EFEFEF;
  margin: 0 auto;
  padding-bottom: 30px;
}
div#contents-wrap div#contents div.one-column {
  width: 100%;
  padding: 10px 0;
}
div#contents-wrap div#contents div.main-column {
  width: 100%;
  padding: 10px 0;
}
div#contents-wrap div#contents div.side-column {
  width: 100%;
  padding: 10px 0;
}
#footer-wrap {
  width: 100%;
  clear: both;
}
#footer-wrap #footer {
  width: 100%;
}
@media all and (min-width: 769px) {
  div#contents-wrap div#contents {
    overflow: hidden;
  }
  div#contents-wrap div#contents div.one-column {
    width: 80%;
    margin: auto;
  }
  div#contents-wrap div#contents div.main-column {
    float: left;
    vertical-align: top;
  }
  div#contents-wrap div#contents div.side-column {
    float: left;
    vertical-align: top;
  }
  div#contents-wrap div#contents div.two-column-73 div.main-column {
    width: 70%;
    padding: 10px 15px 30px 30px;
  }
  div#contents-wrap div#contents div.two-column-73 div.side-column {
    width: 30%;
    padding: 10px 30px 30px 15px;
  }
  div#contents-wrap div#contents div.two-column-7525 div.main-column {
    width: 75%;
    padding: 10px 15px 30px 30px;
  }
  div#contents-wrap div#contents div.two-column-7525 div.side-column {
    width: 25%;
    padding: 10px 30px 30px 15px;
  }
  div#contents-wrap div#contents div.two-column-82 div.main-column {
    width: 80%;
    padding: 10px 15px 30px 30px;
  }
  div#contents-wrap div#contents div.two-column-82 div.side-column {
    width: 20%;
    padding: 10px 30px 30px 15px;
  }
  div#contents-wrap div#contents div.two-column-28 div.side-column {
    width: 20%;
    padding: 10px 15px 30px 30px;
  }
  div#contents-wrap div#contents div.two-column-28 div.main-column {
    width: 80%;
    padding: 10px 30px 30px 15px;
  }
  div#contents-wrap div#contents div.two-column-37 div.side-column {
    width: 30%;
    padding: 10px 15px 30px 30px;
  }
  div#contents-wrap div#contents div.two-column-37 div.main-column {
    width: 70%;
    padding: 10px 30px 30px 15px;
  }
}
