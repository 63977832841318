div.page-error-div {
  width: 100%;
  height: 200px;
  background-color: #FFF;
  padding: 30px;
}
div.page-error-div div.page-error-msg {
  width: 90%;
  margin: auto;
  padding: 5px 10px;
  background-color: #FFEFEF;
  border: 1px solid #FABDA7;
}
div.util-div {
  width: 100%;
  padding: 10px;
}
div.util-div div.success-msg {
  width: 90%;
  margin: auto;
  padding: 5px 10px;
  background-color: #EFFADA;
  border: 1px solid #C2E39B;
  font-size: 80%;
}
div.util-div div.error-msg {
  width: 90%;
  margin: auto;
  padding: 5px 10px;
  background-color: #FFEFEF;
  border: 1px solid #FABDA7;
  font-size: 80%;
}
div.text-with-br p {
  margin: 0;
  margin-bottom: 10px;
}
div.text-with-br p span.read-more {
  color: #1111CC;
  font-size: 80%;
}
div.text-with-br p span.read-more:hover {
  cursor: pointer;
}
div.text-with-br p:last-child {
  margin-bottom: 0;
}
